/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { FC } from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import LandingBg from "../../static/images/landing-background.svg"
import LandingBgSM from "../../static/images/landing-background-s-m.svg"
import { HeaderInfo } from "src/components/HeaderInfo"

interface indexProps {}

const index: FC<indexProps> = () => {
    return (
        <React.Fragment>
            <HeaderInfo appendTitle="Notificaciones instantanias de processos legales"></HeaderInfo>
            <div
                sx={{
                    zIndex: 1,
                    position: "relative",
                }}
            >
                <div
                    sx={{
                        marginTop: [3, 4],
                        marginBottom: [3, 5],
                        maxWidth: ["100%", "100%", 800],
                    }}
                >
                    <p sx={{ variant: "text.heading" }}>
                        Recibe notificaciones de tramites legales.
                    </p>
                    <p sx={{ marginTop: 2, variant: "text.copy" }}>
                        Deje de perder el tiempo buscando actualizaciones sobre
                        procesos legales. Danos el número de caso y te
                        enviaremos actualizaciones directamente a tu correo
                        electrónico. Cubriremos todos los juzgados civiles
                        municipales, del circuito, promiscuous, y de pequeñas
                        causas laborales en Yopal, Aguazul y otras ciudades en
                        Casanare.
                    </p>
                </div>

                <form
                    data-netlify="true"
                    name="Stay updated form"
                    method="POST"
                    action="/success"
                    sx={{
                        my: [4],
                        maxWidth: 800,
                        paddingLeft: [0, 0, 5],
                        paddingRight: [0, 0, 6],
                    }}
                >
                    <label sx={{ variant: "text.label" }}>
                        Enscribite para eschuchar mas
                    </label>
                    <input
                        sx={{
                            fontWeight: "light",
                            display: "block",
                            width: "100%",
                            borderRadius: 1,
                            border: "1px solid",
                            px: 3,
                            py: 3,
                            bg: "muted",
                            color: "darkest",
                            borderColor: "dark",
                            fontSize: 4,
                            fontFamily: "heading",
                            textTransform: "uppercase",

                            "::placeholder": {
                                color: "dark",
                            },
                        }}
                        placeholder={"CORREO ELECTRONICO"}
                        type="email"
                        name="Email"
                    ></input>
                </form>
            </div>
            <img
                //@ts-ignore
                sx={{
                    zIndex: 0,
                    display: ["none", "none", "block"],
                    position: "absolute",
                    "@media screen and (max-height: 950px)": {
                        display: "none",
                    },
                    transform: [
                        "translateX(50%)",
                        "translateX(50%)",
                        "translateX(0%)",
                    ],
                    bottom: theme => theme.footerHeight,
                    right: theme => ["50%", "50%", theme.pagePX],
                    maxWidth: theme => [
                        "auto",
                        "auto",
                        `calc( 100% - ${theme.space[theme.pagePX[2]] * 2 +
                            "px"} )`,
                    ],
                    height: theme =>
                        `calc( 100vh - ${theme.footerHeight} - 5vh )`,
                }}
                alt="Landing page background graphic"
                src={LandingBg}
            ></img>

            <img
                sx={{
                    zIndex: 0,
                    position: "relative",

                    display: ["block", "block", "none"],
                    width: "100%",
                    maxWidth: "700px",
                    margin: "auto",
                    my: [5, 2, 0],

                    // position: "fixed",
                    // bottom: theme => theme.footerHeight,
                    // right: theme => ["50%", "50%", theme.pagePadding],
                    // transform: [
                    //     "translateX(50%)",
                    //     "translateX(50%)",
                    //     "translateX(0%)",
                    // ],
                    // height: theme =>
                    //     `calc( 100vh - ${theme.footerHeight} - 5vh )`,
                }}
                alt="Landing page background graphic"
                src={LandingBgSM}
            ></img>
        </React.Fragment>
    )
}

export default index
